import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's acquire Ridings Coaches" description="Tetley's Coaches acquires another local five vehicle coach operator in Ridings Coaches." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's acquire Ridings Coaches</h1>
              <p className="news-article-date">May 2013</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches acquires another local five vehicle coach operator in Ridings Coaches. Michael Crooke the owner joins our management team and two staff join our driving staff.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
